import { Box, Text } from '@strikelabs/luna';
import React from 'react';

const Intro = () => {
  return (
    <Box maxWidth="90rem" p={[2, 5]} margin="auto">
      <Text mb={1} as="body" ext>
        Buying a home is an exciting prospect — and for most of us, the first
        step is getting a mortgage. How do I get a mortgage? How much can I
        borrow? That&apos;s where a mortgage calculator can help. You can play
        around with different interest rates or deposits to see what your
        monthly payment would look like.
      </Text>
    </Box>
  );
};

export default Intro;
