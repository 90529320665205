import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import BrandImg from 'images/strike-brand.png';

import Hero from 'components/Pages/Mortgages/MortgageCalculator/Sections/Hero';
import Intro from 'components/Pages/Mortgages/MortgageCalculator/Sections/Intro';
import HowDoesItWork from 'components/Pages/Mortgages/MortgageCalculator/Sections/HowDoesItWork';
import FAQ from 'components/Pages/Mortgages/MortgageCalculator/Sections/FAQ';
import MortgageCalculator from 'components/Pages/Mortgages/MortgageCalculator/Sections/MortgageCalculator';
import Disclaimer from 'components/Pages/Mortgages/MortgageCalculator/Sections/Disclaimer';

const Mortgages = () => {
  return (
    <Layout>
      <SEO
        title="Mortgage Calculator | A mortgage calculator page to work out what you can borrow."
        description="This mortgage calculator page is a tool to calculate what mortgage you could get, including what you can borrow and what your monthly repayments might be."
        canonical="/services/mortgage-calculator"
        metaImage={BrandImg}
      />
      <Hero />
      <Intro />
      <MortgageCalculator />
      <HowDoesItWork />
      <FAQ />
      <Disclaimer />
    </Layout>
  );
};

export default Mortgages;
