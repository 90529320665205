import { Box, ButtonAnimated, Text } from '@strikelabs/luna';
import Link from 'components/Link';
import React from 'react';

const HowDoesItWork = () => {
  return (
    <Box maxWidth="90rem" p={[2, 5]} margin="auto">
      <Text mb={2} as="h2" ext>
        How does it work?
      </Text>
      <Text mb={1} as="body" ext>
        How do you use a mortgage calculator? Easy. Just input your gross annual
        income (your income before tax), your deposit and your regular monthly
        outgoings — these are things like loans or childcare, any regular
        monthly expenses. The mortgage calculator will bake it together to give
        you a considered estimate of how much you could borrow. Then pop in the
        interest rate and how long you’ll be borrowing for, and it can show you
        how much your monthly repayments will be. You can see how paying over a
        shorter or longer term will affect your payments.
      </Text>
      <Text mb={1} as="body" ext>
        Though the calculator gives informed and personalised responses,
        it&apos;s just an estimate. When you&apos;re ready to take the next
        step, it can help to talk to an expert. Our colleagues at Strike
        Financial Services have a brilliant team of brokers who scour over
        12,000 deals from over 90 lenders to help you find the mortgage
        you&apos;ve been looking for.
      </Text>
      <Link to="/mortgage-advice">
        <ButtonAnimated id="cta">Get free mortgage advice</ButtonAnimated>
      </Link>
    </Box>
  );
};

export default HowDoesItWork;
