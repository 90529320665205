import { Box, Text } from '@strikelabs/luna';
import React from 'react';

import { Container } from './style';

const Hero = () => {
  return (
    <Container backgroundColor="#F5F5F5">
      <Box maxWidth="90rem" p={[2, 5]} margin="auto">
        <Text as="hero" ext>
          Mortgage calculator
        </Text>
        <Text mt={[3, 4]} as="p" ext>
          With a few basic details, our mortgage calculator can give you a solid
          estimation of what you can borrow, and what your monthly repayments
          might look like.
        </Text>
      </Box>
    </Container>
  );
};

export default Hero;
