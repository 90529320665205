import React from 'react';
import { Accordion, Box, Text } from '@strikelabs/luna';

import Link from 'components/Link';

const list = items => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <Text mb={2} as="body" ext>
            {item}
          </Text>
        </li>
      ))}
    </ul>
  );
};

const faqs = [
  {
    question: 'Who is a mortgage calculator for?',
    answer:
      'Anyone who wants to calculate their mortgage. Whether you’re looking to remortgage, move, buy a second property or you’re a first time buyer. If you have your annual income (before taxes), your regular monthly outgoings and your deposit amount, then you’ll be able to use our handy calculator tool.',
  },
  {
    question: 'What mortgage can I get on my salary?',
    answer:
      'Though it’s different in every situation, as a general rule you could predict on borrowing 4.5 times your yearly income.',
  },
  {
    question:
      'What salary do I need to get a mortgage for £450,000 in the UK? ',
    answer:
      'How much you can borrow depends on various factors, like interest rates and the size of your deposit. But if you’re going by the rule that you might be able to borrow 4.5 times your income, then you’d have to be earning £100,000 per year in order to borrow £450,000.',
  },
  {
    question: 'How can I use my mortgage estimate?',
    answer: (
      <>
        <Text mb={2} as="body" ext>
          Your mortgage estimate is to empower you with information about what
          you might be able to borrow, it’s not a guarantee or concrete right to
          that amount of money. You can use the information to take the next
          step - which would be to{' '}
          <Link to="/mortgage-advice">reach out to a broker</Link>{' '}
          mortgage-advice or lender about securing a{' '}
          <Link to="/mortgages/what-is-a-mortgage-in-principle">
            mortgage in principle,
          </Link>{' '}
          before beginning your house hunt.
        </Text>
        <Text as="body" ext>
          Or, if the calculator shows that you are still a way off the amount
          you’re hoping to borrow, then you might want to use the information to
          inspire your saving journey. Increasing your deposit amount is a great
          way to reduce your monthly repayments.
        </Text>
      </>
    ),
  },
  {
    question: 'How much would monthly repayments be on a £500,000 house?',
    answer: (
      <>
        <Text mb={2} as="body" ext>
          This would depend on your specific borrowing situation, which includes
          the interest rate, borrowing term (how long are you borrowing the loan
          for) and deposit amount.
        </Text>
        <Text mb={2} as="body" ext>
          You could estimate something like this -
        </Text>
        {list(['5% interest rate', '10% interest rate', '25 year term'])}
        <Text mb={2} as="body" ext>
          Your deposit would be £50,000, so your loan would be for £450,000 and
          your LTV ratio would be 90%.
        </Text>
        <Text mb={2} as="body" ext>
          According to our calculations using these factors, your monthly
          repayments would be <b>£2,631.</b>
        </Text>
      </>
    ),
  },
  {
    question: 'How many times my salary can I borrow for a mortgage?',
    answer: (
      <>
        <Text mb={2} as="body" ext>
          What you can borrow in the form of a mortgage depends on lots of
          factors. Our calculator will help you work out how much you can
          borrow, and if you want to work it out as a multiple of your salary,
          you can do this:
        </Text>
        <Text mb={2} as="body" ext>
          Mortgage amount <b>divided by</b> salary{' '}
          <b>= multiple of your salary.</b>
        </Text>
        <Text mb={2} as="body" ext>
          As a general rule of thumb, the average person might hope to borrow
          around 4.5 times their yearly salary.
        </Text>
      </>
    ),
  },
  {
    question: 'What do you mean by regular monthly outgoings?',
    answer: (
      <>
        <Text mb={2} as="body" ext>
          This is basically the cash that you know will regularly be coming out
          of your account - whether that&apos;s in the form of debt, bills or
          subscriptions. Some clear examples of regular monthly outgoings could
          include:
        </Text>
        {list([
          'Car finance repayments',
          'Student loan repayments',
          'Childcare and education',
          'Regular investment contributions',
          'Mobile phone contracts',
        ])}
        <Text mb={2} as="body" ext>
          That’s not an exhaustive list, but it’s a good guide.
        </Text>
      </>
    ),
  },
  {
    question: 'I’ve used the mortgage calculator, what’s the next step?',
    answer: (
      <Text mb={2} as="body" ext>
        The estimate given to you by our mortgage calculator is just for you, it
        doesn’t guarantee you anything. Your next step would probably be{' '}
        <Link to="/mortgages/what-is-a-mortgage-in-principle">
          getting your Mortgage in Principle
        </Link>{' '}
        (also known as a Decision in Principle or Agreement in Principle), which
        you can get via a broker or directly from a lender. Then it’s time to
        start house hunting!
      </Text>
    ),
  },
  {
    question: 'What is the LTV ratio?',
    answer: (
      <>
        <Text mb={2} as="body" ext>
          LTV stands for Loan to Value - it’s a useful calculation used to work
          out how much of the <b>total value of the house</b> is made up of{' '}
          <b>your loan.</b> It is the ratio that compares how much you’re
          borrowing, to the total market value of your house.
        </Text>
        <Text mb={2} as="body" ext>
          This also helps to work out how much equity you have in your house.
        </Text>
        <Text mb={2} as="body" ext>
          A good LTV ratio would be around 80% or lower, meaning you’re paying
          at least a 20% deposit on the house.
        </Text>
        <Text mb={2} as="body" ext>
          If the property’s value was £280,000, you could work out what an LTV
          ratio of 80% would look like in the following way:
        </Text>
        {list([
          "To calculate the loan-to-value (LTV) ratio, you need to determine 80% of the property's value (£280,000). Here's how to calculate it:",
          '80% of £280,000 = 0.8 x £280,000 = £224,000',
        ])}
        <Text mb={2} as="body" ext>
          Therefore, an 80% LTV ratio of a house valued at £280,000 would be
          £224,000. This means that you would need a mortgage loan of £224,000,
          representing 80% of the property&apos;s value, and the remaining 20%
          (£56,000) would be the deposit or equity you contribute towards the
          purchase.
        </Text>
      </>
    ),
  },
  {
    question: 'Should I wait until my deposit is bigger to buy a house?',
    answer:
      'One of the easiest ways to decrease your monthly repayments is to increase your deposit. If you believe that you can maintain your current living situation (e.g. paying rent) whilst increasing your savings or deposit, then it may be worth waiting. Growing your savings pot is a great way to reduce your LTV ratio, increase your equity in the property and reduce your monthly repayments.',
  },
  {
    question: 'What does “interest rate” mean?',
    answer: (
      <>
        <Text mb={2} as="body" ext>
          One of the assumptions that you can change on our calculator is the
          “interest rate”. The interest rate, also known as your “mortgage
          rate”, is how much you will be charged to borrow your loan. Our tool
          lets you see how a range of interest rates might change your mortgage
          repayments. You can experiment with different interest rates, or you
          can make an informed guess about what the interest rate may be set at,
          like 5%.
        </Text>
        <Text mb={2} as="body" ext>
          In general if you have a <b>larger deposit,</b> then you will be
          offered a better deal, which means the interest rate will be lower. It
          could be a good idea to set interest rates to the highest they could
          be, to see if you could still afford your monthly repayments.{' '}
          <Link to="/mortgage-advice">Reach out to us</Link> if you need us to
          help you work out what your interest rate may be.
        </Text>
      </>
    ),
  },
  {
    question: 'How long do most people borrow the loan for?',
    answer:
      'The term of a mortgage is the how long it will be repaid over, and the typical mortgage is repaid over 25, 30, or even 35 years, with the UK average currently sitting at 30 years.',
  },
];

const FAQ = () => {
  return (
    <Box maxWidth="90rem" p={[2, 5]} margin="auto">
      <Text mb={2} as="h2" ext>
        Mortgage Calculator FAQs
      </Text>
      {faqs.map(faq => (
        <Accordion key={faq.question} label={faq.question}>
          {faq.answer}
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
