import { Box, FullMortgageCalculator, Text } from '@strikelabs/luna';
import React from 'react';
import useInterestRate from '../../../../../../hooks/useInterestRate';

const MortgageCalculator = () => {
  const { zohoState } = useInterestRate({
    autoGet: true,
  });
  return (
    <Box maxWidth="90rem" px={[2, 5]} margin="auto">
      <Text mb={2} as="h2" ext>
        How much could you borrow?
      </Text>
      {!zohoState.loading && (
        <FullMortgageCalculator
          interest={zohoState?.data?.mortgage_interest_rate}
        />
      )}
    </Box>
  );
};

export default MortgageCalculator;
