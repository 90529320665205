import { useWebGet, useZoho as useZohoApi } from '@strikelabs/vega';

const { GATSBY_API_URL } = process.env;

const useInterestRate = ({ authRequest = false, autoGet }) => {
  const { zohoState, callZoho } = useZohoApi({
    baseUrl: GATSBY_API_URL,
    useGetHook: useWebGet,
    authRequest,
    autoGet,
  });

  return {
    zohoState,
    callZoho,
  };
};

export default useInterestRate;
