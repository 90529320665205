import React from 'react';
import { Box, Text } from '@strikelabs/luna';
import Link from 'components/Link';

const Disclaimer = () => {
  return (
    <Box maxWidth="90rem" p={[2, 5]} margin="auto">
      <Text styleType="caption" mb={1}>
        There may be a fee for mortgage advice. The actual amount you pay will
        depend upon your circumstances. The fee is up to 1% but a typical fee is
        £299.
      </Text>
      <Text styleType="caption" mb={1}>
        Your home could be repossessed if you don&apos;t keep up repayments on
        your mortgage.
      </Text>
      <Text styleType="caption" mb={1}>
        ©{new Date().getFullYear()} Strike Financial Services Ltd. All rights
        reserved. Strike Financial Services Limited is an appointed
        representative of Mortgage Advice Bureau Limited and Mortgage Advice
        Bureau (Derby) Limited which are authorised and regulated by the
        Financial Conduct Authority. Strike Financial Services Limited.
        Registered Office: 650 The Crescent, Colchester Business Park, Essex
        England, CO4 9YQ
      </Text>
      <Text styleType="caption" mb={2}>
        We hope you never need to use it, but here&apos;s our financial services{' '}
        <Link
          target="_blank"
          to="https://strike.co.uk/legal/financial-services-complaints-procedure"
          aria-label="financial services complaints procedure information"
        >
          complaints procedure.
        </Link>
      </Text>
    </Box>
  );
};

export default Disclaimer;
